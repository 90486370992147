html {
  background-color: #FCFCFC;
}

.main {
  margin-top: 65px;
  /* margin-left: 2rem; */
  margin-right: 2rem;
  padding: 2rem;
  padding-bottom: 0;
  height: calc(100vh - 2rem - 65px);
  display: flex;
  flex-direction: column;
}
